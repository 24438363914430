// import React from "react";
// import classes from "./Pagination.module.css";
// function CustomPagination({ pageCount, pageIndex, gotoPage }) {
//   // Calculate the range of page numbers to display
//   const pageNumbers = [];
//   for (let i = 0; i < pageCount; i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <div className={classes.CustomPagination} display="flex" justifyContent="center" alignItems="center" width="100%">
//       {pageNumbers.map((page) => (
//            <button
//            style={{display:"flex",justifyContent:"center",alignItems:"center"}}
           

//             key={page}
//             onClick={() => gotoPage(page)}
//             className={` ${pageIndex === page ?  "good" : ""}`}
//           >
//             {page + 1}
//           </button>
//        ))}
//     </div>
//   );
// }

// export default CustomPagination;


// import React from 'react';
// import classes from "./Pagination.module.css";
// import { GrFormNext,GrFormPrevious } from "react-icons/gr";
// import { useTranslation } from 'react-i18next';
// const CustomPagination = ({
//   canPreviousPage,
//   canNextPage,
//   pageCount,
//   pageIndex,
//   // pageOptions,
//   gotoPage,
//   previousPage,
//   nextPage,
// }) => {
//   const { i18n} = useTranslation();

//   const maxPagesToShow = 10;

//   // Calculate the range of page numbers to display
//   const startPage = Math.max(0, pageIndex - Math.floor(maxPagesToShow / 2));
//   const endPage = Math.min(pageCount - 1, startPage + maxPagesToShow - 1);

//   const pages = [];
//   for (let i = startPage; i <= endPage; i++) {
//     pages.push(
//       <button
//         key={i}
//         onClick={() => gotoPage(i)}
//         className={i === pageIndex ? 'good' : ''}
//       >
//         {i + 1}
//       </button>
//     );
//   }

//   return (
//     <div  className={classes.CustomPagination}  >
//       <button onClick={() => previousPage()} disabled={!canPreviousPage}>
//         { i18n.language === "ar" ? (<GrFormNext style={{color:"#fff"}}/>):(<GrFormPrevious style={{color:"#fff"}}/>)}
//        </button>
//       {pages}
//       <button  onClick={() => nextPage()} disabled={!canNextPage}>
//       {i18n.language === "ar" ? (<GrFormPrevious style={{color:"#fff"}}/>) :  (<GrFormNext style={{color:"#fff"}}/>)}
//       </button>
//     </div>
//   );
// };

// export default CustomPagination;

import React, { useState } from 'react';
import classes from "./Pagination.module.css";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

const CustomPagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  gotoPage,
  previousPage,
  nextPage,
}) => {
  const { i18n } = useTranslation();

  const maxPagesToShow = 10;

  const startPage = Math.max(0, pageIndex - Math.floor(maxPagesToShow / 2));
  const endPage = Math.min(pageCount - 1, startPage + maxPagesToShow - 1);

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <button
        key={i}
        onClick={() => gotoPage(i)}
        className={i === pageIndex ? 'good' : ''}
      >
        {i + 1}
      </button>
    );    
  }

  return (
    <div className={classes.CustomPagination}>
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        {i18n.language === "ar" ? (
          <GrFormNext style={{ color: "#fff" }} />
        ) : (
          <GrFormPrevious style={{ color: "#fff" }} />
        )}
      </button>
      {pages}
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        {i18n.language === "ar" ? (
          <GrFormPrevious style={{ color: "#fff" }} />
        ) : (
          <GrFormNext style={{ color: "#fff" }} />
        )}
      </button>
    </div>
  );
};

export default CustomPagination;
