import React from "react";
// import { Route, Routes } from "react-router-dom";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import NotFound from "./NotFound";
import PrivateRoute from "./PrivateRoute";
import { Main, Patients, Markteing } from "../featuers/dashbord/pages/index";
import { AdminLogin } from "../featuers/auth/pages/index";
import OptPage from "../featuers/auth/login/OptPage";
import NewPassword from "../featuers/auth/login/NewPassword";
import Profile from "../featuers/dashbord/pages/Profile";
import ChangePassword from "../featuers/dashbord/pages/ChangePassword";
import Forgotpassword from "../featuers/dashbord/components/changePassword/ForgetPassword";

function Routing() {
  return (
    <Routes>
      <Route path="/adminlogin" element={<AdminLogin />} />
      <Route path="/OTP" element={<OptPage />} />
      <Route path="/NewPassword" element={<NewPassword />} />
      <Route path="*" element={<NotFound />} />
      <Route element={<PrivateRoute />}>
        <Route path="/" exact element={<Main />} />
        <Route path="/patients" exact element={<Patients />} />
        <Route path="/markteing" exact element={<Markteing />} />
        <Route path="/Profile" exact element={<Profile />} />
        <Route path="/ForgetPassword" exact element={<Forgotpassword />} />
      </Route>
    </Routes>
  );
}

export default Routing;
