import React from 'react'
import CardDashbord from '../components/card/CardDashbord'
import Chart from '../components/chart/Chart'

function Main() {
  return (
    <div>
      <CardDashbord/>
      <Chart/>
    </div>
  )
}

export default Main