import React from 'react';
import Chart from 'react-apexcharts';

class BarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          type: 'bar',
          height: 350,
          width: '100%'
        },
        xaxis: {
          categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        },
        toolbar: {
          show: false // Hides the toolbar/menu
        }
      },
      series: [{
        name: 'Series 1',
        data: [30, 40, 45, 50, 49, 60, 70]
      }]
    };
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height="350"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BarChart;
