//test git
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
// import * as FiIcons from "react-icons/fi";
import { FiTrash2, FiEdit } from "react-icons/fi";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Button,
  Center,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import axios from "axios";
import classes from "../../../featuers/dashbord/table/table Pagination/Pagination.module.css";

const MarketingTable = ({ handleDelete, handleUpdate }) => {
  const [marketingdata, setmarketingdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  console.log(marketingdata);
  //pagination

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const npage = Math.ceil(filteredData.length / recordsPerPage);

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const firstIndex = (currentPage - 1) * recordsPerPage;
  const lastIndex = Math.min(currentPage * recordsPerPage, filteredData.length);
  var records;
  if (Array.isArray(filteredData)) {
    records = filteredData.slice(firstIndex, lastIndex);
    //console.log(records);
  } else {
    // console.error("data.tableData is not an array.");
  }

  const [t] = useTranslation();

  const [query, setQuery] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

 

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [id, setId] = useState();
  const [name, setname] = useState();
  const [link, setLink] = useState();
  const [precentage, setPrecentage] = useState();
  const [MarkterLink,setMarkterLink]=useState('')

  const handelQuery = (e) => {
    setQuery(e.target.value);
  };

  const search = (data) => {
    // Update keys based on your marketing data structure
    const keys = [
      "MarkterName",
      "CountPatint",
      "Percintage",
      "CountPaid",
      "LinkId",
    ];

    return data.filter((item) =>
      keys.some((key) => {
        const value = item[key];
        return (
          value !== undefined &&
          value !== null &&
          value.toString().toLowerCase().includes(query.toLowerCase())
        );
      })
    );
  };

  const fetcheMarketingData = async () => {
    try {
      const { datamarketing } = await axios
        .get(`https://clinicapi.mygatein.com/Markter/ListMarkter`)
        .then((response) => {
          const testdata =
            (response.data[0] && JSON.parse(response.data[0].jsonData)) || [];
          setmarketingdata(testdata);
          setFilteredData(testdata); // Set filtered data initially
        });
      console.log("datamarketing", datamarketing);
    } catch (e) {
      // console.log("error");
    }
  };

  useEffect(() => {
    fetcheMarketingData();
  }, []);

  useEffect(() => {
    setFilteredData(search(marketingdata));
  }, [query, marketingdata]);

  //console.log(marketingdata)

  const editFormHandler = async (e) => {
    e.preventDefault();

    const datasend = {
      MarkterId: id,
      MarkterName: name,
      MarkterLink: link,
      MarkterPercenatg: precentage,
    };
    // console.log(datasend);
    try {
      // console.log(JSON.stringify(datasend));
      const { data } = await axios.get(
        `https://clinicapi.mygatein.com/Markter/EditMarkterById?markterid=${JSON.stringify(
          datasend
        )}`
      );
      fetcheMarketingData();
      onClose();
    } catch (e) {
      // console.log("error");
    }
    setSecondModalOpen(false);
  };

  function openLink(link) {
    window.open(link, "_blank");
  }

  const [nameError, setNameError] = useState("");
  const [linkError, setlinkError] = useState("");
  const [perError, setPerError] = useState("");

  const handleNameChange = (e) => {
    const enteredName = e.target.value;
    setname(enteredName);

    // Check if the entered name meets the minimum length requirement (e.g., 3 characters)
    if (enteredName.length === 0) {
      // Set an error message
      setNameError(t("general.required"));
    } else if (enteredName.length < 3) {
      setNameError(t("general.validation_length"));
    } else if (enteredName.length > 20) {
      setNameError(t("general.validationTooLong"));
    } else {
      // Clear the error message if the name is valid
      setNameError("");
    }
  };

  const validateLink = (link) => {
    const enteredLink = link.target.value;
    setLink(enteredLink);

    // Check if the entered name meets the minimum length requirement (e.g., 3 characters)
    if (enteredLink.length === 0) {
      // Set an error message
      setlinkError(t("general.required"));
    } else if (!enteredLink.startsWith("http")) {
      setlinkError("Please enter a valid link");
    } else {
      // Clear the error message if the name is valid
      setlinkError("");
    }
  };

  const validatePer = (per) => {
    const enteredper = per.target.value;
    setPrecentage(enteredper);

    // Check if the entered name meets the minimum length requirement (e.g., 3 characters)
    if (enteredper.length === 0) {
      // Set an error message
      setPerError(t("general.required"));
    } else if(enteredper>100){
      setPerError(t("general.precentageTooHigh"));
    }else if (enteredper < 0 ) {
            setPerError(t("general.precentageTooLow"));
    } else {
      // Clear the error message if the name is valid
      setPerError("");
    }
  };

  //SECOND MODAL

  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  // const [editModalValues, setEditModalValues] = useState({
  //   MarkterName: "", // Add other fields as needed
  // });

  // Function to open the second modal


  function removeHash(url) {
    // Find the position of '#' character
    const hashIndex = url.indexOf('#');
    
    // If '#' is found, remove everything after it
    if (hashIndex !== -1) {
        return url.substring(0, hashIndex);
    }
    
    // If '#' is not found, return the original URL
    return url;
}

// Test the function



  const openEditModal = (data) => {
    // setEditModalValues(data); // 'data' should contain your default values
    console.log(data)
    setSecondModalOpen(true);
    setname(data.MarkterName);
    setPrecentage(Math.floor(data.MarkterPercenatg* 100)); 
    setId(data.MarkterId);
    setLink(removeHash(data.MarkterLink))
  };

  // Function to close the second modal
  const closeSecondModal = () => {
    // setEditModalValues({
    //   MarkterName: "", // Reset other fields as needed
    // });
    setSecondModalOpen(false);
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const initialDeleteRef = useRef();
  const finalDeleteRef = useRef();

  const openDeleteModal = (data) => {
    setDeleteModalOpen(true);
    setId(data.MarkterId);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const deleteHandler = async (e) => {
    e.preventDefault();

    const datasend = {
      MarkterId: id,
    };
    // console.log(datasend);
    try {
      console.log(JSON.stringify(datasend));
      const { data } = await axios.get(
        `https://clinicapi.mygatein.com/Markter/Markter/DeleteMarkterById?markterid=${JSON.stringify(
          datasend
        )}`
      );
      // console.log(data);
      fetcheMarketingData()
      onClose();
    } catch (e) {
      // console.log("error");
    }
    closeDeleteModal();
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
    reset
  } = useForm();

  useEffect(() => {
    if (isOpen) {
      reset(); // Assuming reset is a function to reset the form provided by useForm()
    }
  }, [isOpen]);


  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };
  return (
    <div>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>
            {t("general.add_marketing")}
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <form
            onSubmit={handleSubmit(async (values) => {
              values.MarkterId = null;
              try {
                await axios.get(
                  `https://clinicapi.mygatein.com/Markter/AddMarkter?iddata=${JSON.stringify(
                    values
                  )}`
                );
                fetcheMarketingData();
                onClose();
              } catch (e) {
                console.log("error");
              }
            })}
          >
            <ModalBody pb={6}>
              <Stack spacing={6}>
                <FormControl isInvalid={errors.MarkterName}>
                  <FormLabel
                    htmlFor="MarkterName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("general.name")}
                  </FormLabel>
                  <Controller
                    name="MarkterName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("general.required"),
                      minLength: {
                        value: 2,
                        message: t("general.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("general.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("general.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("general.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("general.name")}
                        onChange={(e) => {
                          handleInputChange(e, "MarkterName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.MarkterName && errors.MarkterName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.MarkterLink}>
                  <FormLabel
                    htmlFor="MarkterLink"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("general.link")}
                  </FormLabel>
                  <Controller
                    name="MarkterLink"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("general.required"),
                      pattern: {
                        value: /^(ftp|http|https):\/\/[^#\s]+$/i,
                        message: t("general.invalid_link"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("general.no_only_spaces"),
                        noHash: (value) =>
                          !value.includes("#") ||
                          t("general.no_hash_symbol"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("general.link")}
                        onChange={(e) => {
                          handleInputChange(e, "MarkterLink");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.MarkterLink && errors.MarkterLink.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.MarkterPercenatg}>
                <FormLabel htmlFor="MarkterPercenatg" style={{ textTransform: "capitalize" }}>
                  {t("general.precentage")}
                </FormLabel>
                <Controller
                  name="MarkterPercenatg"
                  control={control}
                  rules={{
                    required: t("general.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      if (isNaN(intValue)) {
                        return t("general.invalid_number");
                      }
                      return (
                        (intValue >= 0 && intValue <= 100) || t("general.between_zero_and_hundred")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("general.precentage")}
                      onChange={(e) => {
                        handleInputChange(e, "MarkterPercenatg");
                      }}
                      type="number"
                      min="0"
                      max="100"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.MarkterPercenatg && errors.MarkterPercenatg.message}
                </FormErrorMessage>
              </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter gap={2}>
              <Button colorScheme="blue" type="submit">
                {t("general.save")}
              </Button>
              <Button onClick={onClose}> {t("general.Cancel")} </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isSecondModalOpen}
        onClose={closeSecondModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>
            {t("general.edit_markter")}
          </ModalHeader>
          <form onSubmit={editFormHandler}>
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>{t("general.name")}</FormLabel>
                <Input
                  ref={initialRef}
                  placeholder={t("general.name")}
                  onChange={handleNameChange}
                  value={name}
                />
                {nameError && (
                  <p style={{ color: "red", fontSize: "14px" }}>{nameError}</p>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>{t("general.link")} </FormLabel>
                <Input
                  ref={initialRef}
                  placeholder={t("general.link")}
                  onChange={validateLink}
                  value={link}
                />
                {linkError && (
                  <p style={{ color: "red", fontSize: "14px" }}>{linkError}</p>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>{t("general.precentage")} </FormLabel>
                <Input
                  placeholder={t("general.precentage")}
                  type="number"
                  onChange={validatePer}
                  value={precentage}
                />
                {perError && (
                  <p style={{ color: "red", fontSize: "14px" }}>{perError}</p>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter gap={2}>
              <Button
                colorScheme="blue"
                type="submit"
                isDisabled={
                  !!nameError ||
                  name === undefined ||
                  !!linkError ||
                  link === undefined ||
                  !!perError ||
                  precentage === undefined
                }
              >
                {t("general.save")}
              </Button>
              <Button onClick={closeSecondModal}>
                {" "}
                {t("general.Cancel")}{" "}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        initialFocusRef={initialDeleteRef} // Add the ref for initial focus
        finalFocusRef={finalDeleteRef} // Add the ref for final focus
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>
            {t("general.delete")}
          </ModalHeader>
          <ModalBody pb={6}>
            <FormControl justifyContent={"center"} alignItems={"center"}>
              {/* <FormLabel>{t("general.confirm_delete")}</FormLabel> */}
              <Text fontSize={"18px"}>
                {t("general.confirm_delete_message")}
              </Text>
            </FormControl>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button
              colorScheme="red"
              onClick={deleteHandler} // Implement the function to handle delete
            >
              {t("general.delete")}
            </Button>
            <Button onClick={closeDeleteModal}>{t("general.Close")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box display={"flex"} justifyContent={"end"}>
        <Button
          colorScheme="teal"
          size="md"
          onClick={onOpen}
          textTransform={"capitalize"}
        >
          {t("general.add_marketing")}
        </Button>
      </Box>
      {/* </Flex>
      </Flex> */}

      <Input
        placeholder={t("general.Search Marketing Data")}
        value={query}
        onChange={handelQuery}
        // margin="10px 0"
        borderRadius="10px 10px 0 0"
        border="none"
        marginTop={"10px"}
        backgroundColor={"light"}
      />
      <Box
        bg="light"
        overflow="auto"
        borderRadius={"0 0 10px 10px"}
        boxShadow={"0px 35px 50px rgba( 0, 0, 0, 0.2 )"}
        minHeight={"80%"}
        className="MarktingTable"
        // padding={"10px"}
      >
        <Table borderRadius={"5px"}>
          <Thead>
            <Tr>
              {/* <Th>#</Th> */}
              <Th backgroundColor={"#46a0cd"} color={"white"}>
                {t("general.name")}
              </Th>
              <Th backgroundColor={"#46a0cd"} color={"white"}>
                {t("general.count")}
              </Th>
              <Th backgroundColor={"#46a0cd"} color={"white"}>
                {t("general.precentage")}
              </Th>
              <Th backgroundColor={"#46a0cd"} color={"white"}>
                {t("general.Profits")}
              </Th>
              <Th backgroundColor={"#46a0cd"} color={"white"}>
                {t("general.count_paid")}
              </Th>
              <Th backgroundColor={"#46a0cd"} color={"white"}>
                {t("general.link")}
              </Th>
              <Th backgroundColor={"#46a0cd"} color={"white"}>
                {t("general.action")}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {records.length > 0 ? (
              records.map((el, index) => (
                <Tr key={el.MarkterId}>
                  {/* <Td>{index + 1}</Td> */}
                  <Td style={{ cursor: "pointer" }}>{el.MarkterName}</Td>
                  <Td style={{ cursor: "pointer" }}>{el.CountPatint}</Td>
                  <Td style={{ cursor: "pointer" }}>
                    {Math.floor(el.MarkterPercenatg* 100) } %
                  </Td>
                  <Td style={{ cursor: "pointer" }}>{el.Percintage}</Td>
                  <Td style={{ cursor: "pointer" }}>{el.CountPaid}</Td>
                  <Td
                    style={{ cursor: "pointer" }}
                    onClick={() => openLink(el.LinkId)}
                  >
                    {el.LinkId}
                  </Td>
                  <Td className="activeIcon">
                    <Flex gap={2} justifyContent={"center"}>
                      <Button
                        bg="#46a0cd"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "primary",
                          color: "primary",
                        }}
                        onClick={() =>
                          openEditModal({
                            MarkterId: el.MarkterId,
                            MarkterName: el.MarkterName,
                            MarkterPercenatg: el.MarkterPercenatg,
                            MarkterLink:el.LinkId
                          })
                        }
                      >
                        <FiEdit />
                      </Button>
                      <Button
                        bg="red.600"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "red.600",
                          color: "red.600",
                        }}
                        onClick={() =>
                          openDeleteModal({
                            MarkterId: el.MarkterId,
                          })
                        }
                      >
                        <FiTrash2 />
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))
            ) : (
              <div>no data found </div>
            )}
          </Tbody>
        </Table>
      </Box>

      <div className={classes.CustomPage}>
        <div
          onClick={prePage}
          style={{
            cursor: "pointer",
            backgroundColor: "#46a0cd",
            borderRadius: "100%",
            padding: "5px 15px",
            fontWeight: "bold",
            fontSize: "20px",
            border: "1px solid #00000036",
          }}
        >
          {"<"}
        </div>
        {Array.from({ length: npage }, (_, index) => (
          <div
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            style={{
              cursor: "pointer",
              backgroundColor: currentPage === index + 1 ? "white" : "#46a0cd",
              borderRadius: "100%",
              padding: "5px 10px",
              fontWeight: currentPage === index + 1 ? "bold" : "normal",
              color: currentPage === index + 1 ? "#46a0cd" : "white",
              border: "1px solid #00000036",
              minWidth: "34px",
              textAlign: "center",
            }}
          >
            {index + 1}
          </div>
        ))}
        <div
          onClick={nextPage}
          style={{
            cursor: "pointer",
            backgroundColor: "#46a0cd",
            borderRadius: "100%",
            padding: "5px 15px",
            fontWeight: "bold",
            fontSize: "20px",
            border: "1px solid #00000036",
          }}
        >
          {">"}
        </div>
      </div>

      <div className={`${classes.pagination}`}>
        <div className={classes.pagination_section_2}></div>
      </div>
    </div>
  );
};

export default MarketingTable;
