import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Loading from "../../../../sheard components/Loading";
// import { updatePatientTableData } from "../../../../store/patientDataTableSlice";
import { setUpdateNotes } from "../../../../store/updataNotes";
import ModalInfo from "./ModalInfo";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const ModalView = ({
  modalData,
  modalErrorr,
  modalLoading,
  rowId,
  isOpen,
  onOpen,
  onClose,
  // isStutes
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [doctorNote, setNotes] = useState({});

  const saveNots = async (id) => {
    const token = JSON.parse(localStorage.getItem("access-token")||sessionStorage.getItem("access-token"));

    try {
      const response = await axios.put(
        `${baseUrl}/Patient/EditPatient/${id}`,
        { doctorNote, newDate: new Date() },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(setUpdateNotes(response.data));
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  // تعريف حالة لتتبع ما إذا تم تعديل البيانات بالفعل

  // const saveNots = async (id) => {
  //   const token = JSON.parse(localStorage.getItem("access-token"));

  //   // تحقق مما إذا تم تعديل البيانات بالفعل
  //   if (!isStutes) {
  //     try {
  //       const response = await axios.put(
  //         `https://clinicapi.mygatein.com/Patient/EditPatient/${id}`,
  //         { doctorNote, newDate: new Date() },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       dispatch(setUpdateNotes(response.data));

  //       // قم بتعيين الحالة إلى true بعد الإرسال الأول
  //       // isStutes = true;
  //     } catch (error) {
  //       console.error("Error updating data:", error);
  //     }
  //   } else {
  //     // لا ترسل التاريخ إذا تم التعديل بالفعل
  //     try {
  //       const response = await axios.put(
  //         `https://clinicapi.mygatein.com/Patient/EditPatient/${id}`,
  //         { doctorNote },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       dispatch(setUpdateNotes(response.data));
  //     } catch (error) {
  //       console.error("Error updating data:", error);
  //     }
  //   }
  // };

  return (
    <>
      <Modal onClose={onClose} size={"xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          {modalLoading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "50px",
              }}
            >
              <Loading />
            </Box>
          ) : (
            <>
              <ModalHeader textTransform={"capitalize"} textAlign={"center"} fontSize={"25px"}> {modalData?.name}</ModalHeader>
              <ModalBody>
                <Box ref={componentRef}>
                  <ModalInfo
                    objectData={modalData && modalData}
                    setNotes={setNotes}
                  />
                </Box>
              </ModalBody>
              <ModalFooter>
                <Flex justifyContent={"space-around"} width="100%">
                  <Button
                    flexBasis={"30%"}
                    colorScheme="green"
                    onClick={() => {
                      saveNots(rowId);
                      onClose();
                      // dispatch(updatePatientTableData(rowId));
                    }}
                  >
                    {t("general.Save")}
                  </Button>
                  <Button
                    onClick={handlePrint}
                    flexBasis={"30%"}
                    colorScheme="cyan"
                    color={"white"}
                  >
                    {t("general.Print")}
                  </Button>
                  <Button
                    flexBasis={"30%"}
                    onClick={onClose}
                    color={"white"}
                    colorScheme="red"
                  >
                    {t("general.Close")}
                  </Button>
                </Flex>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalView;
