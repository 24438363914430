import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import classes from "./index.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { insertUserData, restTrials } from "../../../store/login";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";

const FormLogin = ({ setForgotPassword }) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    userName: Yup.string().required(
      t("general.User name / E-mail is required")
    ),
    password: Yup.string().required(t("general.Password is required")),
  });
  const { users, loading, message, numberOfTries } = useSelector(
    (state) => state.login
  );

  console.log(numberOfTries)
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    userName: "",
    password: "",
    // rememberMe: false,
  };

  const handleSubmit = async (values, { resetForm }) => {
    await dispatch(insertUserData(values))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    resetForm({ values: "" });
  };

  useEffect(() => {
    if (users && users.roleId) {
      navigate("/");
    }
  }, [users, navigate]);

  const [captcha, setRecaptcha] = useState("");

  const handleCaptcha = (value) => {
    setRecaptcha("changed");
    console.log(value)
    console.log("hi")
    dispatch(restTrials())
  };

  return (
    <>
      <ToastContainer />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className={classes.form}>
            <div style={{ width: "100%" }}>
              <Field name="userName">
                {({ field }) => (
                  <InputGroup>
                    <Input
                      {...field}
                      pr="4.5rem"
                      type="text"
                      placeholder={t("general.User Name")}
                      variant="outline"
                      className={classes.username}
                      bg={"#fff"}
                    />
                  </InputGroup>
                )}
              </Field>
              <ErrorMessage
                name="userName"
                component="div"
                className={
                  i18n.language === "en" ? classes.errorEn : classes.errorAr
                }
              />
            </div>

            <div style={{ position: "relative", width: "100%" }}>
              <Field name="password">
                {({ field }) => (
                  <InputGroup size="md">
                    <Input
                      {...field}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("general.Password")}
                      variant="outline"
                      className={classes.password}
                      p={4}
                      bg={"#fff"}
                    />
                    {i18n.language === "en" ? (
                      <InputRightElement
                        width="4.5rem"
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-end"}
                      >
                        <Button
                          h="1.75rem"
                          size="sm"
                          color="#a0a9b4"
                          background="transparent"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    ) : (
                      <InputLeftElement
                        width="4.5rem"
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-end"}
                      >
                        <Button
                          h="1.75rem"
                          size="sm"
                          color="#a0a9b4"
                          background="transparent"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? "إخفاء" : "عرض"}
                        </Button>
                      </InputLeftElement>
                    )}
                  </InputGroup>
                )}
              </Field>
              <ErrorMessage
                name="password"
                component="div"
                className={
                  i18n.language === "en" ? classes.errorEn : classes.errorAr
                }
              />
            </div>

            {i18n.language === "en"
              ? message && <Text className={classes.textRed}>{message}</Text>
              : message && (
                  <Text className={classes.textRed}>
                    اسم المستخدم او كلمة السر خاطئة
                  </Text>
                )}

            <div className={classes.submitdiv}>
              <div className={classes.allRememberMe}>
                <label
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Field
                    type="checkbox"
                    name="rememberMe"
                    style={{ position: "relative", top: "-1px" }}
                  />
                  <span className={classes.rememberMe}>
                    {t("general.Remember Me ?")}
                  </span>
                </label>
              </div>

              <p
                className={classes.forgotpassword}
                onClick={() => setForgotPassword(true)}
              >
                {t("general.Forgot your password ?")}
              </p>
            </div>

            <div>
            
              {numberOfTries >= 3 ? (
                <div style={{marginTop:"20px"}}>
                  <ReCAPTCHA
                      key={i18n.language}
                    hl={i18n.language === "ar" ? "ar" : "en"}
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={handleCaptcha}
                  />
                </div>
              ) : (
                <button
                  type="submit"
                  className={classes.submitbutton}
                  title={t("general.login")}
                >
                  {loading ? t("general.Loading") : t("general.LOGIN")}
                </button>
              )}
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default FormLogin;
