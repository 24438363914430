import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Bounce, toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// fetching
export const insertUserData = createAsyncThunk(
  "user/insertUserData",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(`${baseUrl}/Admin/Login`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(values),
        mode: "cors",
      });
      const data = await res.json();
      console.log(data)

      if (data.rememberMe) {
        localStorage.setItem("current-user", JSON.stringify(data));
        localStorage.setItem("access-token", JSON.stringify(data.token));
      } else {
        sessionStorage.setItem("current-user", JSON.stringify(data));
        sessionStorage.setItem("access-token", JSON.stringify(data.token));
      }

      return data;
    } catch (e) {
      console.log(e)

      return rejectWithValue(e.message);
    }
  }
);

export const restTrials = createAsyncThunk(
  "user/restTrials",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const ForgetPasswordEmail = createAsyncThunk(
  "user/ForgetPasswordEmail",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(`${baseUrl}/Admin/MailForgetPassword?email=${values}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        mode: "cors",
      });
      const data = await res.json();
      //console.log(data)
      return data
    } catch (e) {
      console.log(e) 

      return rejectWithValue(e.message);
    }
  }
);


export const CheckOTP = createAsyncThunk(
  "user/CheckOTP",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch(`${baseUrl}/Admin/CheckOTP?otp=${values.OTP}&userid=${values.emailId }`, {
        method: 'POST',
        headers: {
          "Content-type": "application/json",
        },
        mode: "cors",
      });
      const data = await res.json();
      //console.log(data)
      return data
    } catch (e) {
      console.log(e)

      return rejectWithValue(e.message);
    }
  }
);


export const ChangePassword = createAsyncThunk(
  "user/ChangePassword",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
   
    const requestBody = {
      Password: values.password, 
    };
    try {
      const res = await fetch(`${baseUrl}/Admin/ResetPassword?userid=${values.userId}`, {
        method: 'PUT',
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${values.token}`, // Include the token in the headers
        },
        body: JSON.stringify(requestBody),
        mode: "cors",
      });
      //const data = await res.json();
      console.log(res.status)
      //console.log(data)

      return res.status
    } catch (e) {
      console.log(e)

      return rejectWithValue(e.message);
    }
  }
);


 
// console.log(insertUserData)
// if (data.rememberMe) {

const initialState = {
  users: localStorage.getItem("current-user")
    ? JSON.parse(localStorage.getItem("current-user"))
    : JSON.parse(sessionStorage.getItem("current-user")),
  loading: null,
  error: null,
  message: "",
  numberOfTries: 0,
  emailId:null
};
// }

//   const initialState = {
//     users: sessionStorage.getItem("current-user")
//       ? JSON.parse(sessionStorage.getItem("current-user"))
//       : {},
//     loading: null,
//     error: null,
//     message: ""
//   }

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [insertUserData.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },

    [insertUserData.fulfilled]: (state, action) => {
      // const responseMessage = action.payload.message;
      // console.log("responseMessage",responseMessage)
      state.users = action.payload;
      console.log("state.users", state.users);
      state.loading = false;
      state.message = action.payload.message;
      console.log("state.message", state.message);
      if (state.message === "Login Successfully") {
        state.numberOfTries = 0;
      } else if (
        state.message === "Incorrect Email/username OR password" ||
        state.message ===
          "Account locked due to too many failed login attempts try again in 10 Min"
      ) {
        state.numberOfTries++;
      }

      console.log("state", state.numberOfTries);
    },

    [insertUserData.rejected]: (state, action) => {
      state.numberOfTries++;
      if (state.numberOfTries < 3) {
        toast.error(
          "Please fill in all required fields correctly and try again!",
          {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          }
        );
      } else {
        toast.error("3", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }

      state.error = action.payload;
      console.log("state", state.numberOfTries);
      state.loading = false;
    },

    [restTrials.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },

    [restTrials.fulfilled]: (state, action) => {
      state.numberOfTries = 0;
      state.loading = false;
    },

    [restTrials.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;

      console.log(state.error)
    },



    [ForgetPasswordEmail.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },

    [ForgetPasswordEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.emailId=action.payload
      console.log(state.emailId)
    },

    [ForgetPasswordEmail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;

    },


    [CheckOTP.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },

    [CheckOTP.fulfilled]: (state, action) => {
      state.loading = false;
       state.otpRES=action.payload
      //console.log(state.otpRES)
    },

    [CheckOTP.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;

    },



    [ChangePassword.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },

    [ChangePassword.fulfilled]: (state, action) => {
      state.loading = false;
       state.ChnagePasswordRes=action.payload
      console.log(state.ChnagePasswordRes)
    },

    [ChangePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;

    }

  },
});

export default userSlice.reducer;
