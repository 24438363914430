import React from 'react';
import MarketingTable from '../components/MarketingTable';

const Markteing = () => {
  return (
    <div>
      <MarketingTable></MarketingTable>
    </div>
  );
}

export default Markteing;
