// import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";

const RoutesProvider = ({ children }) => {
  return (
    <HashRouter>
      {children}
    </HashRouter>
  )
}

export default RoutesProvider