import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiSettings } from "react-icons/ci";
import Loader from "../../auth/components/Loader/Loader";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Profile = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`${baseUrl}/Admin/Profile?username=Admin@niyat.com.sa`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access-token") ||
              sessionStorage.getItem("access-token")
          )}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data)

  const { i18n, t } = useTranslation();
  const [accordionIndex, setAccordionIndex] = useState(0);

  const handleAccordionChange = (index) => {
    setAccordionIndex(index === accordionIndex ? -1 : index);
  };

  if(loading){
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Stack
        spacing={12}
        p={4}
        borderRadius={6}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        bg="white"
      >
        <SimpleGrid spacing={6} columns={{ base: 1, md: 2 }}>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("general.nameAr")}
            </Text>
            <Text marginBlock={2}>
              {data.fullNameAr}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("general.nameEn")}
            </Text>
            <Text marginBlock={2}>
            {data.fullNameEng}

            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("general.Email")}
            </Text>
            <Text marginBlock={2}>
            {data.userName}


            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("general.role")}
            </Text>
            <Text marginBlock={2}>
            {data.roleName}

            </Text>
          </Box>
        </SimpleGrid>

 
      </Stack>


    </div>
  );
};

export default Profile;
