import React from 'react';
import * as XLSX from 'xlsx';
 import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

function ExportToExcel({ data }) {
  const [t] = useTranslation();

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'data.xlsx');
  };

  return (
    <Button onClick={handleExport} bg="#46A0CD" color={"#fff"} size='md' marginBottom='10px'>
    {t('general.export_file')}
  </Button>
  );
}

export default ExportToExcel;
