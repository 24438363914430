import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  Link as ChakraLink,
  useTheme,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";

import { toggleSidebar } from "../../store/sidebarSlice";
import routes from "../../routing/routes";
import { Niyat } from "../../assets";
import * as FiIcons from "react-icons/fi";
import { BsPersonHearts } from "react-icons/bs";

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.sidebar);
  const theme = useTheme();
  const [isLargeThan992] = useMediaQuery("(min-width: 992px)");

  const handleAutoCloseSidebar = () => {
    if (isLargeThan992 === false) {
      dispatch(toggleSidebar());
    }
  };

  const getCurrentDir = {
    [theme.start]: { base: sidebar.isActive ? 0 : "-260px", lg: 0 },
  };
  const links = [
    {
      path: "/",
      icon: <FiIcons.FiHome size={18} />,
      text: t("general.Main"),
      subLinks: null,
    },
    {
      path: "/patients",
      icon: <FiIcons.FiFileText size={18} />,
      text: t("general.Patients"),
      subLinks: null,
    },
    {
      path: "/markteing",
      icon: <BsPersonHearts size={18} />,
      text: t("general.markiting"),
      subLinks: null,
    },
  ];

  const IconText = (link) => (
    <>
      <Flex
        flex="1"
        alignItems="center"
        justifyContent={{
          base: "start",
          lg: sidebar.isActive ? "center" : "start",
        }}
      >
        {link.icon}
        <Text
          as="p"
          fontSize="1rem"
          textTransform="capitalize"
          paddingStart={4}
          display={{ base: "block", lg: sidebar.isActive ? "none" : "block" }}
          fontWeight="500"
        >
          {link.text}
        </Text>
      </Flex>
    </>
  );

  return (
    <>
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        // bg="rgba(0, 0, 0, .3)"
        zIndex={98}
        display={{ base: sidebar.isActive ? "block" : "none", lg: "none" }}
        onClick={handleAutoCloseSidebar}
        {...getCurrentDir}
        cursor="pointer"
        role="button"
      />

      <Box
        width={{ base: "260px", lg: sidebar.isActive ? "80px" : "260px" }}
        height="100vh"
        transition=".3s"
        overflowY="auto"
        bg="#46A0CD"
        position="fixed"
        top="0"
        borderEnd="1px solid"
        borderColor="border"
        color="#fff"
        {...getCurrentDir}
        zIndex={99}
        // {!sidebar.isActive && _hover={{width: "260px"}} }
      >
        <ChakraLink
          as={Link}
          to={routes.dashboard_general}
          background={"#fff"}
          p={4}
          height="70px"
          borderBottom="1px solid"
          borderColor="border"
          display="flex"
          justifyContent={"space-around"}
          alignItems="center"
          textTransform="capitalize"
          fontSize="1.2rem"
        >
          <Image src={Niyat} alt="brand" objectFit="contain" height="189%" />
          {!sidebar.isActive && (
            <Text color={"#03BEC7"} fontWeight={"bold"}>
              {t("general.niyat clincs")}
            </Text>
          )}
        </ChakraLink>

        <Text
          as="h5"
          marginTop={6}
          marginBottom={4}
          textTransform="capitalize"
          textAlign="center"
          fontSize="1.2rem"
          fontWeight="600"
          paddingInline={4}
          letterSpacing={i18n.language === "en" ? "3px" : "0"}
        >
          {!sidebar.isActive && t("layout.sidebar.dashboard")}
        </Text>

        <Accordion allowToggle border="transparent">
          {links.map((link, idx) =>
            link.subLinks === null ? (
              <Flex
                key={idx}
                end
                as={NavLink}
                to={link.path}
                className="sidebar-link"
                color="#fff"
                _hover={{ bg: "none" }}
                borderBottom="1px solid"
                borderColor="border"
                paddingInline={4}
                borderRadius={4}
                paddingBlock={3}
                onClick={handleAutoCloseSidebar}
              >
                {IconText(link)}
              </Flex>
            ) : (
              <AccordionItem key={idx}>
                <AccordionButton
                  color="#fff"
                  _hover={{ bg: "none" }}
                  borderBottom="1px solid"
                  borderColor="border"
                  paddingInline={4}
                  borderRadius={0}
                  margin={0}
                  paddingBlock={3}
                >
                  {IconText(link)}
                </AccordionButton>
                {!sidebar.isActive && (
                  <AccordionPanel
                    pb={4}
                    paddingBottom={2}
                    paddingTop={1}
                    paddingStart={8}
                  >
                    <UnorderedList
                      color="#fff"
                      fontSize="1rem"
                      listStyleType="none"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                    >
                      {link.subLinks?.map((subLink, j) => (
                        <ListItem key={j}>
                          <ChakraLink
                            as={NavLink}
                            to={subLink.path}
                            textTransform="capitalize"
                            _hover={{ color: "primary" }}
                          >
                            {t(`${subLink.text}`)}
                          </ChakraLink>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </AccordionPanel>
                )}
              </AccordionItem>
            )
          )}
        </Accordion>
      </Box>
    </>
  );
};

export default Sidebar;
