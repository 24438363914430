import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Forgotpassword from './Forgotpassword';
import FormLogin from './FormLogin';
import classes from './index.module.css';
function Login() {
    const [t] = useTranslation();

    const [isForgotPassword,setForgotPassword] =useState(false);
  return (
    <div className=''>
       <h1 className={classes.niyat_clinck}>{t("general.Niyat Clinics")}</h1>
      {isForgotPassword ? <Forgotpassword setForgotPassword={setForgotPassword}/>: <FormLogin setForgotPassword={setForgotPassword}/>}

    </div>
  )
}

export default Login