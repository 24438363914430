import { Button, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import classes from "./index.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import { ChangePassword } from "../../../store/login";
import styles from "./OTP.module.css";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";


const NewPassword = () => {




  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  // const [password, setpassword] = useState("");
  // const [passwordAgain, setpasswordAgain] = useState("");
  // const [error, seterror] = useState(false);

  const { loading, emailId, otpRES } = useSelector((state) => state.login);

  console.log(otpRES);

  // useEffect(() => {
  //   if (passwordAgain != password) {
  //     seterror(true);
  //   } else if (passwordAgain === password) {
  //     seterror(false);
  //   }
  // }, [passwordAgain, password]);

  //console.log(error)
  // const values = {
  //   userId: emailId,
  //   password: password,
  //   token: otpRES.token,
  // };

  const dispatch = useDispatch();

  // const handelsubmit = (e) => {
  //   e.preventDefault();
  //   if(password.length==0){
  //           seterror(t("general.enter_the_new_password"));

  //   }
  //   else if (passwordAgain != password) {
  //     seterror(t("general.password_doesnot_match"));
  //     console.log("error")
  //   }else if(password.length<8){
  //     seterror(t("general.your_password_is_too_weak"));
  //   } else if (passwordAgain === password) {
  //     seterror(false);
  //       console.log(values);
  //       dispatch(ChangePassword(values)).then((result) => {
  //         //console.log(result)
  //         if (result.payload == 200) {
  //           console.log("changed done");
  //           toast.success("changed Done", {
  //             position: "top-center",
  //             autoClose: 1000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //             transition: Bounce,
  //           });
  //           setTimeout(() => {
  //             navigate("/adminlogin");
  //           }, 1000);
  //         } else {
  //           console.log("something went wrong ");
  //         }
  //       });
  //   }
  
  // };

  const [showPassword, setShowPassword] = useState(false);


  const [lang, setlang] = useState("en");
  useEffect(() => {
    setlang(document.documentElement.lang);
  }, [lang]);
  console.log(lang);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  }


   const validationSchema = Yup.object().shape({
     password: Yup.string()
       .required(t("general.Password_is_required"))
       .min(8, t("general.Password_must_be_at_least_8_characters"))
       .matches(
         /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
         t(
           "general.Password_must_contain_at_least_one_special_character_one_uppercase_letter_and_one_number"
         )
       ),
     confirmPassword: Yup.string()
       .required(t("general.Confirm_Password_is_required"))
       .oneOf([Yup.ref("password"), null], t("general.Passwords_must_match")),
   });


  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: "20px",
        marginTop: "-150px",
      }}
    >
      <ToastContainer />
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // Handle form submission here
          values.token=otpRES.token
          values.userId = emailId;
          // values.password = values.password;
                    console.log(values);
                        dispatch(ChangePassword(values)).then((result) => {
                          //console.log(result)
                          if (result.payload == 200) {
                            console.log("changed done");
                            toast.success("changed Done", {
                              position: "top-center",
                              autoClose: 1000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                              transition: Bounce,
                            });
                            setTimeout(() => {
                              navigate("/adminlogin");
                            }, 1000);
                          } else {
                            console.log("something went wrong ");
                          }
                        });

        }}
      >
        <Form style={{ width: "500px", height: "500px" }}>
          <div className={styles.group}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Field
                name="password"
                type={showPassword ? "text" : "password"}
                className={styles.input}
                style={{
                  width: "100%",
                  marginTop: "200px",
                }}
              />
              <Button
                h="1.75rem"
                size="sm"
                color="#a0a9b4"
                background="transparent"
                onClick={() => setShowPassword(!showPassword)}
                style={{ marginTop: "100px" }}
              >
                {showPassword ? t("general.Hide") : t("general.Show")}
              </Button>
            </div>
            <ErrorMessage
              name="password"
              component="div"
              className="error"
              style={{
                color: "red",
              }}
            />
            <label
              className={
                lang === "en" ? styles.labeltesten : styles.labeltestar
              }
              style={{ marginTop: "180px" }}
            >
              {t("general.new_password")}
            </label>
          </div>

          <div className={styles.group}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Field
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                className={styles.input}
                style={{
                  width: "100%",
                  marginTop: "200px",
                }}
              />

              <Button
                h="1.75rem"
                size="sm"
                color="#a0a9b4"
                background="transparent"
                onClick={() => setShowPassword(!showPassword)}
                style={{ marginTop: "100px" }}
              >
                {showPassword ? t("general.Hide") : t("general.Show")}
              </Button>
            </div>
            <ErrorMessage
              name="confirmPassword"
              component="div"
              className="error"
              style={{
                color: "red",
              }}
            />
            <label
              className={
                lang === "en" ? styles.labeltesten : styles.labeltestar
              }
              style={{ marginTop: "180px" }}
            >
              {t("general.Confirm_new_password")}
            </label>
          </div>

          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{ marginTop: "30px" }}
          >
            <button
              onClick={() => navigate("/adminlogin")}
              className={classes.back}
            >
              {t("general.Back")}
            </button>
            <button type="submit" className={classes.send}>
              {t("general.Send")}
            </button>
          </Flex>
        </Form>
      </Formik>
    </div>
  );
};

export default NewPassword;



      //  onSubmit = { handelsubmit };