import { Box, Textarea } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "10px",
  padding: "15px 5px",
};

const inputStyle = {
  padding: "8px 3px",
  backgroundColor: "#EEF1F6",
  borderRadius: "5px",
  disabled: true,
  paddingInlineStart: "10px",
};
function ModalInfo({ objectData, setNotes }) {
  const { t } = useTranslation();
  const { users } = useSelector((state) => state.login);
  // const [testText, setTestText] = useState(objectData?.doctorNote);

  const handle = (e) => {
    const value = e.target.value;

    // if (testText && testText.includes(value)) {
    //   setTestText((prevItems) =>
    //     prevItems.filter((item) => item !== value)
    //   );
    // } else {
    //   setTestText(value);
    // }

    setNotes(value);
  };
  return (
    <>
      <Box fontSize={{ base: "12px", md: "16px" }} style={gridStyle} className="responsiveModalInfo">
        <div >
          <label style={{ width: "100%" }}>{t("general.Name")} : </label>
          <p style={inputStyle} disabled className="cursor-not-allowed">
            {objectData?.name}{" "}
          </p>
        </div>
        <div>
          <label style={{ width: "100%" }}>{t("general.Phone Number")} : </label>
          <p style={inputStyle} disabled className="cursor-not-allowed">
            {objectData?.phoneNumber}
          </p>
        </div>
        <div>
          <label style={{ width: "100%" }}>{t("general.City")} : </label>
          <p style={inputStyle} disabled className="cursor-not-allowed">
            {" "}
            {objectData?.city}
          </p>
        </div>
        <div>
          <label style={{ width: "100%" }}>{t("general.Service")} : </label>
          <p style={inputStyle} disabled className="cursor-not-allowed">
            {" "}
            {objectData?.service}
          </p>
        </div>
        <div>
          <label style={{ width: "100%" }}>{t("general.Clinic")} : </label>
          <p style={inputStyle} disabled className="cursor-not-allowed">
            {" "}
            {objectData?.clinic}
          </p>
        </div>
        <div>
          <label style={{ width: "100%" }}>{t("general.Communication Time")} : </label>
          <p style={inputStyle} disabled className="cursor-not-allowed">
            {" "}
            {objectData?.communicationTime}
          </p>
        </div>
        <div>
          <label style={{ width: "100%" }}>{t("general.Nearest Branch")} : </label>
          <p style={inputStyle} disabled className="cursor-not-allowed">
            {" "}
            {objectData?.nearestBranch}
          </p>
        </div>
        <div>
          <label style={{ width: "100%" }}>{t("general.How You Know Us")} : </label>
          <p style={inputStyle} disabled className="cursor-not-allowed">
            {" "}
            {objectData?.knowAboutus}
          </p>
        </div>
      </Box>

      {users && users.roleId === 2 ? null : (
        <div className="w-full">
       
          <Textarea
            onChange={handle}
            width={"100%"}
            backgroundColor="#eef1f6"
            border="none"
            outline={"none"}
            p="20px"
            marginBottom={"20px"}
            max="5"
            placeholder={t("general.Notes")}
            defaultValue={objectData?.doctorNote}
          />
        </div>
      )}
    </>
  );
}

export default ModalInfo;
