import React, { useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../../sheard components/Loading";
import { useTable, useSortBy, usePagination } from "react-table";
import * as moment from "moment";
import axios from "axios";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import ModalView from "../components/modal/ModalView";
import CustomPagination from "./table Pagination/CustomPagination";
import { SearchPagination } from "./table Pagination/Pagination";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function TablePatients({ tableData, loading, error, isStutes,setData,setLoading }) {
  console.log(tableData)
  const [t] = useTranslation();

  const [amountModalOpen, setAmountModalOpen] = useState(false);
  const [PaidAmount, setPaidAmount] = useState();
  const [PatientId, setPatientId] = useState();
  const [modalData, setModalData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalErrorr, setModalError] = useState(null);
  const [rowId, setRowId] = useState();
  const [PaidError, setPaidError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const fetchData2 = async () => {
    try {
      const response = await axios.get(`${baseUrl}/Patient/GetPatients`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(
              localStorage.getItem("access-token") ||
              sessionStorage.getItem("access-token")
            )}`
        }
      });
      setData(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };


  const fetchData = useCallback((id) => {
    const token = JSON.parse(
      localStorage.getItem("access-token") ||
        sessionStorage.getItem("access-token")
    );
    setModalLoading(true);

    axios
      .get(`${baseUrl}/Patient/GetSingelPatient/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setModalData(response.data);
        setModalLoading(false);
      })
      .catch((error) => {
        setModalError(error);
        setModalLoading(false);
      });
  }, []);

  const handelClick = () => {
    onOpen();
  };

  const handelcLickAmount = (value) => {
    openAmountModal();
    setPatientId(value);
  };

  const openAmountModal = () => {
    setAmountModalOpen(true);
  };

  const handelPaidAmount = async (e) => {
    e.preventDefault();

    const dataSend = {
      PaidAmount: PaidAmount,
      id: PatientId,
    };

    if (PaidAmount !== undefined && PaidAmount >= 1 ) {
      try {
        //console.log(JSON.stringify(datasend))
        const { data } = await axios.get(
          `https://clinicapi.mygatein.com/Markter/EditPatientPaidById?id=${JSON.stringify(
            dataSend
          )}`
        )
        console.log(data);
        setAmountModalOpen(false);
        toast.success(t("Data Has Been Saved Successfully"), {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchData2()
        setPaidError(false)
        
      } catch (e) {
        console.log("error");
      }
    }else{
      setPaidError(true)
        }
  };

  const Columns = [
    {
      Header: t("general.Name"),
      accessor: "name",
    },
    {
      Header: t("general.Phone Number"),
      accessor: "phoneNumber",
    },
    {
      Header: t("general.Gender"),
      accessor: "gender",
    },
    {
      Header: t("general.BirthDate"),
      accessor: "birthDate",
      Cell: (props) => <div> {moment(props.value).format("DD/MM/YYYY")} </div>,
    },
    {
      Header: t("general.CommunicationTime"),
      accessor: "communicationTime",
    },
    {
      Header: t("general.City"),
      accessor: "city",
    },
    {
      Header: t("general.clinic"),
      accessor: "clinic",
    },

    {
      Header: t("general.Service"),
      accessor: "service",
    },
    {
      Header: t("general.NearestBranch"),
      accessor: "nearestBranch",
    },
    {
      Header: t("general.know Aboutus"),
      accessor: "knowAboutus",
    },
    {
      Header: t("general.Status"),
      accessor: "status",
      Cell: (props) => (
        <Box
          color={props.value === t("general.communicated") ? "green" : "red"}
          fontWeight="bold"
        >
          {" "}
          {props.value}{" "}
        </Box>
      ),
    },
    {
      Header: t("general.sign Date"),
      accessor: "signDate",
      Cell: (props) => (
        <div>
          {" "}
          {props.value &&
            moment(props?.value).format("DD/MM/YYYY HH:mm:ss")}{" "}
        </div>
      ),
    },
    {
      Header: t("general.last Update"),
      accessor: "lastUpdate",
      Cell: (props) => (
        <div>
          {" "}
          {props.value
            ? moment(props?.value).format("DD/MM/YYYY HH:mm:ss")
            : "---"}{" "}
        </div>
      ),
    },
    {
      Header: t("general.PaidAmount"),
      accessor: "paidAmount",
    },
    
  ];

  const columns = useMemo(() => Columns, []);

  const Data = useMemo(() => tableData, [tableData]);

  console.log(Data)
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Show",
        Header: t("general.Show"),
        Cell: ({ row }) => (
          <button
            onClick={() => handelClick(row.values)}
            style={{
              cursor: "pointer",
              backgroundColor: "#46A0CD",
              color: "white",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            {t("general.Show")}
          </button>
        ),
      },
      {
        id: "PaidAmountButton",
        Header: t("general.PaidAmount"),
        Cell: ({ row }) => (
          <button
            onClick={() => handelcLickAmount(row.original.id)}
            style={{
              cursor: "pointer",
              backgroundColor: "#46A0CD",
              color: "white",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            {t("general.PaidAmount")}
          </button>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    setPageSize,
    pageCount,
    pageOptions,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: Data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination,
    tableHooks
  );

  if (loading) {
    return (
      <Box margin="auto" padding="50px" width="100px">
        <Loading />
      </Box>
    );
  }

  return (
    <div>
      <Modal
        isOpen={amountModalOpen}
        size={"xl"}
        onClose={() => {
          setPaidError(false)
          setAmountModalOpen(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("general.PaidAmount")}</ModalHeader>
          <ModalCloseButton />

          <form onSubmit={handelPaidAmount}>
            <ModalBody pb={6}>
              <FormControl mt={4}>
                <FormLabel>{t("general.PaidAmount")} </FormLabel>
                <Input
                  placeholder={t("general.PaidAmount")}
                  type="number"
                  onChange={(e) => setPaidAmount(e.target.value)}
                />
              </FormControl>
              {
                PaidError&&(
                  <div style={{marginTop:"15px",fontSize:"18px",color:"red"}}>
                  {t("general.paidAmmount_error")}
                  </div>
                )
              }
             
            </ModalBody>

            <ModalFooter>
              <Flex justifyContent={"flex-end"} width="50%" gap={2}>
                <Button colorScheme="green" type="submit" flexBasis={"40%"}>
                  {t("general.save")}
                </Button>
                <Button
                  flexBasis={"40%"}
                  onClick={() => {
                    setPaidError(false)
                    setAmountModalOpen(false)
                  }}
                  color={"white"}
                  colorScheme="red"
                >
                  {t("general.Close")}
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <section className="table-wrapper">
        <table {...getTableProps()} className="fl-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? "🔽"
                          : "🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {tableData.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "50px",
              }}
            >
              {t("general.There are no patients.")}
            </div>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => {
                      fetchData(row.cells[0].row.original.id);
                      setRowId(row.cells[0].row.original.id);
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            maxWidth: "100px", // Set your desired maximum width
                            whiteSpace: "pre-wrap", // Enable wrapping of long words
                            wordWrap: "break-word", // Allow long words to be broken and wrap onto the next line
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </section>

      <CustomPagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
      />

      <SearchPagination
        gotoPage={gotoPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        loading={loading}
      />

      {/* modal show */}
      <ModalView
        // row={row}
        rowId={rowId}
        modalErrorr={modalErrorr}
        modalLoading={modalLoading}
        modalData={modalData && modalData}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        // isStutes={isStutes}
      />
      <ToastContainer />
    </div>
  );
}

export default TablePatients;
