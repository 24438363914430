import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./sidebarSlice"
import login from "./login";
import {tableData} from "./tableData";
import updateNotaSlice from "./updataNotes";
const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    login,
    // tableData:patientsDataTableSlice,
    [tableData.reducerPath]: tableData.reducer, // Add the RTK Query reducer

    updateNotaSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(tableData.middleware), // Add the RTK Query middleware
});

export default store;