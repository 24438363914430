import { Box } from "@chakra-ui/react";
import React from "react";
import Navbar from "../components/navbar/Navbar";
import Login from "../login/Login";

function AdminLogin() {
  return (
    <div style={{ height: "calc(100vh - 70px)" }}>
      <Navbar />
      <Box marginTop={"80px"}>
        <Login />
      </Box>
    </div>
  );
}

export default AdminLogin;
