// directionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const updateNotaSlice = createSlice({
  name: 'updata_notes',
  initialState: {} , 
  reducers: {
    setUpdateNotes: (state, action) => { 
      return action.payload;
    },
   
  },
});

export const { setUpdateNotes } = updateNotaSlice.actions;
export default updateNotaSlice.reducer;
