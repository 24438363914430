import { Button, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { error_page } from "../assets";
 function NotFound() {
  const navigate = useNavigate();
  return (
    <Flex width={"100%"} height={"100%"} justifyContent="center" alignContent="center" marginTop={"100px"}>
      <Button colorScheme="cyan" color={"white"} onClick={()=>navigate("./",{replece:true})}>
        Back
      </Button>
      <Image src={error_page} alt="error page" />
    </Flex>
  );
}

export default NotFound;
