import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import classes from "./index.module.css";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { ForgetPasswordEmail } from "../../../store/login";
import Loader from "../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

const Forgotpassword = ({ setForgotPassword }) => {
  const {  loading, message,emailId } = useSelector(
    (state) => state.login
  );
  //console.log(emailId)
  
  const [t, i18n] = useTranslation();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("general.Invalid email"))
      .required(t("general.Email is required")),
  });

  const initialValues = {
    email: "",
  };
  const dispatch =useDispatch()
  const navigate = useNavigate()
  const [invaliedEmial,setinvaliedEmial]=useState(false)
  const handleSubmit = (values) => {
    // Handle form submission here
    console.log(values.email);
    dispatch(ForgetPasswordEmail(values.email)).then((result)=>
    {
      if(result.payload === 0 || result.payload ===undefined){
        
        console.log("invalied email")
        setinvaliedEmial(true)
      }else{
        console.log("navigate")
        navigate("/OTP")
      }
    }
    )
  };

  if(loading){
    return(
      <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}>
        <Loader/>
      </div>
    )
  }

  return (
    <div>
      <Flex
        flexDirection="column"
        justifyContent={"start"}
        alignItems="center"
        marginInlineStart={"10px"}
      >
        <h2 className={classes.forgot_your_password}>
          {t("general.Forgot your password")}
        </h2>
        <p className={classes.enter_email}>
          {t("general.Please enter your e-mail")}
        </p>
      </Flex>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className={classes.formForgotPassword}>
          <div>
            <Field
              type="email"
              id="email"
              name="email"
              placeholder={t("general.Email")}
              className={classes.email}
            />
            <ErrorMessage
              name="email"
              component="div"
              className={
                i18n.language === "en" ? classes.errorEn : classes.errorAr
              }
            />
          </div>
          {
            invaliedEmial&&(
              <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginBottom:"25px"}}>
              <h1 style={{color:"red"}}>
              {t("general.wrong_email")}
              </h1>

              </div>
            )
          }
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <button
              className={classes.back}
              onClick={() => setForgotPassword(false)}
            >
              {t("general.Back")}
            </button>
            <button type="submit" className={classes.send}>
              {t("general.Send")}
            </button>
          </Flex>
        </Form>
      </Formik>
    </div>
  );
};

export default Forgotpassword;
