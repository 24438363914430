import { useTranslation } from "react-i18next";
import { Button, Flex, Image, Text } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { Niyat } from "../../../../assets";

const Navbar = () => {
  const { i18n, t } = useTranslation();

  return (
    <Flex
      as="nav"
      paddingInline={4}
      alignItems="center"
      bg="#fff"
      height="70px"
      color="#000"
    >
      <Flex alignItems="center" width="100%" justifyContent="space-around">
        <Image src={Niyat} alt="logo" w="100px" h="70px" />

        <Flex alignItems="center" gap={3}>
          <Button
            type="button"
            p={0}
            bg="none"
            height="auto"
            _hover={{ bg: "none" }}
            color="#000"
            gap={2}
            onClick={() => {
              i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
              window.location.reload();
            }}
          >
            <FiIcons.FiGlobe title={t("general.Change Language")} size={20} />
            {i18n.language === "en" ? (
              <Text>English</Text>
            ) : (
              <Text>العربية</Text>
            )}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Navbar;
