import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Input } from "@chakra-ui/react";

import TablePatients from "../table/TablePatients";
import ExportToExcel from "../../../sheard components/ExportToExcel";
import axios from "axios";
import { useLocation } from "react-router-dom/dist";
// import { useFetchDataQuery } from "../../../store/tableData";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

function Patients() {
  const { t } = useTranslation();
  // const { data:tableDataa, error:tableError, isLoading:tableLoading } = useFetchDataQuery([]);
  // const dispatch = useDispatch();
  // const { tableData, loading, error } = useSelector((state) => state.tableData);
  const [editbleData, setEditbleData] = useState([]);
  const { updateNotaSlice } = useSelector((state) => state);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState("");
  
  // useEffect(() => {
  //    dispatch(fetchPatientTableData());
  // }, [dispatch]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/Patient/GetPatients`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(
              localStorage.getItem("access-token") ||
              sessionStorage.getItem("access-token")
            )}`
        }
      });
      setData(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateNotaSlice) {
      let newData = data.map((item) => {
        return item.id === updateNotaSlice.id
          ? {
              ...item,
              isNew: updateNotaSlice.isNew,
              lastUpdate: updateNotaSlice.lastUpdate,
            }
          : item;
      });
      setData(newData);
    }
  }, [updateNotaSlice]);

  // const [isStutes,setStutes] =useState("")
  // console.log("34664367",isStutes)

  // console.log(data)
  useEffect(() => {
    if (query.toLowerCase() === "male") {
      const filteredAndMappedData = data
        .filter((item) => item.gender === "Male")
        .map((item) => ({
          id: item.id,
          name: item.name,
          phoneNumber: item.phoneNumber,
          gender: item.gender,
          status: item.isNew ? t("general.new") : t("general.communicated"),
          communicationTime: item.communicationTime,
          birthDate: item.birthDate,
          city: item.city,
          clinic: item.clinic,
          knowAboutus: item.knowAboutus,
          service: item.service,
          nearestBranch: item.nearestBranch,
          signDate: item.newDate,
          lastUpdate: item.lastUpdate,
          paidAmount:item.paidAmount
        }));

      setEditbleData(filteredAndMappedData);
    } else {
      const newArray =
        data &&
        data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            phoneNumber: item.phoneNumber,
            gender: item.gender,
            status: item.isNew ? t("general.new") : t("general.communicated"),
            communicationTime: item.communicationTime,
            birthDate: item.birthDate,
            city: item.city,
            clinic: item.clinic,
            knowAboutus: item.knowAboutus,
            service: item.service,
            nearestBranch: item.nearestBranch,
            signDate: item.newDate,
            lastUpdate: item.lastUpdate,
            paidAmount:item.paidAmount
          };
        });
      setEditbleData(newArray);
    }
  }, [query, data, t]);

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     const newArray =
  //       data &&
  //       data.map((item) => {
  //         return {
  //           id: item.id,
  //           name: item.name,
  //           phoneNumber: item.phoneNumber,
  //           gender: item.gender,
  //           status: item.isNew ? t("general.new") : t("general.communicated"),
  //           communicationTime: item.communicationTime,
  //           birthDate: item.birthDate,
  //           city: item.city,
  //           clinic: item.clinic,
  //           knowAboutus: item.knowAboutus,
  //           service: item.service,
  //           nearestBranch: item.nearestBranch,
  //           signDate: item.newDate,
  //           lastUpdate: item.lastUpdate,
  //         };
  //       });
  //     setEditbleData(newArray);
  //   }
  // }, [data, t]);

  // useEffect(() => {
  //   if ()
  // }, [])

  // useEffect(()=>{
  //   if(updateNotaSlice){
  //      editbleData.map(item =>{
  //       return setStutes(item.id === updateNotaSlice.id && item.stutes)
  //     })

  //   }

  // },[editbleData,updateNotaSlice])
  // search state

  const handelQuery = (e) => {
    setQuery(e.target.value);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.query) {
      setQuery(location.state.query);
    }
  }, [location.state]);

  // filter search
  const keys = [
    "name",
    "phoneNumber",
    "gender",
    "city",
    "nearestBranch",
    "status",
    "service",
    "communicationTime",
    "knowAboutus",
  ];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) =>
        item[key].toString().toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  // useEffect(() => {
  //   if (query.toLowerCase() === "male") {
  //     const filteredAndMappedData = data
  //       .filter((item) => item.gender === "Male")
  //       .map((item) => ({
  //         id: item.id,
  //         name: item.name,
  //         phoneNumber: item.phoneNumber,
  //         gender: item.gender,
  //         status: item.isNew ? t("general.new") : t("general.communicated"),
  //         communicationTime: item.communicationTime,
  //         birthDate: item.birthDate,
  //         city: item.city,
  //         clinic: item.clinic,
  //         knowAboutus: item.knowAboutus,
  //         service: item.service,
  //         nearestBranch: item.nearestBranch,
  //         signDate: item.newDate,
  //         lastUpdate: item.lastUpdate,
  //       }));

  //     setEditbleData(filteredAndMappedData);
  //   }
  // }, [query, data, t]);

  return (
    <div>
      <ExportToExcel data={editbleData} />
      <Input
        placeholder={t("general.search")}
        value={query}
        onChange={handelQuery}
        marginBottom="10px"
      />
      <TablePatients
        loading={loading}
        error={error}
        tableData={editbleData && search(editbleData)}
        setData={setData}
        setLoading={setLoading}
      />
    </div>
  );
}

export default Patients;
