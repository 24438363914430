import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom"
import AppLayout from "../layout/AppLayout"

function PrivateRoute() {
  const { users } = useSelector((state) => state.login);
  
   return (users && users?.roleId) ? <AppLayout><Outlet /></AppLayout> : <Navigate to="/adminlogin"/>;
}

export default PrivateRoute
